import React, { useState } from "react";
import { Layout, Image, Button, Form, Input } from "antd";
import { Lottie } from "@crello/react-lottie";
import "roboto-mono-webfont";
import animationData from "~/assets/animations/cooking.json";
import { TopNav, PoweredBy } from "~/components";
import { emailSignIn } from "~/services/auth";

const { Header, Footer, Content } = Layout;

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault(); // Add this line
    setIsLoading(true);
    try {
      const userCredential = await emailSignIn(email, password);
      // Handle user session or redirect
      console.log("Login successful", userCredential);
    } catch (error) {
      console.error("Login error", error);
      // Handle errors (e.g., show an alert or notification)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Header>
        <TopNav />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div id="signin">
          <Image src={require("~/assets/img/logo.png")} />
          <Lottie
            style={{ margin: "0 auto" }}
            width="375px"
            height="216px"
            config={{
              animationData: animationData,
              loop: true,
              autoplay: true,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "100px",
            }}
          >
            <Form
              style={{ width: "50%" }}
              labelCol={{ style: { textAlign: "left" } }}
            >
              <form onSubmit={handleSignIn}>
                {" "}
                {/* Move onSubmit here */}
                <Form.Item label="Email" style={{ marginBottom: "20px" }}>
                  <Input
                    style={{ width: "100%" }}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                </Form.Item>
                <Form.Item label="Password">
                  <Input.Password
                    style={{ width: "100%" }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit" // This is correct
                    loading={isLoading}
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </form>
            </Form>
          </div>
        </div>
      </Content>
      <Footer>
        <PoweredBy />
      </Footer>
    </Layout>
  );
};
