import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Layout,
  Table,
  Tag,
  Space,
  Typography,
  Menu,
  Dropdown,
  Modal,
  message,
  Switch,
  Button,
  Row,
  Col,
  Avatar,
  Select,
} from "antd";
import {
  EllipsisOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import {
  getUsers,
  approveUser,
  activateUser,
  deactivateUser,
  User,
  setLunchplan,
  updateLocation,
} from "~/services/users";
import { TopNav, PoweredBy, SendPushModal } from "~/components";
import { fetchLocations } from "~/services/locations";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { db } from "~/services/firebase";


const { Header, Footer, Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

export const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [tokens, setTokens] = useState<string[]>([]);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationMap, setLocationMap] = useState({}); // Mapping of location IDs to addresses for display purposes.
  const [initialLocations, setInitialLocations] = useState([]); // Initial locations of selected user when the modal opens.
  const [currentLocations, setCurrentLocations] = useState([]); // Current locations selected in the modal.
  // useMemo to keep a memoized version of selected user based on user ID to avoid re-renders.
  const selectedUserMemo = useMemo(() => selectedUser, [selectedUser ? selectedUser.id : null]);

  useEffect(() => {
    let isMounted = true; // flag to check if the component is mounted
  
    const loadData = async () => {
      if (isMounted) {
        const users = await getUsers();
        setUsers(users);
      }
    };
  
    loadData();
  
    return () => {
      isMounted = false; // set it to false when component unmounts
    };
  }, []);
  


  // Modal visibility effect
  // Updates initial and current locations when the modal is shown or resets when hidden.
  useEffect(() => {
      if (locationModalVisible && selectedUserMemo) {
          console.log("Modal is open with selected user:", selectedUserMemo);
          const locationIds = selectedUserMemo.locationId || [];
          setInitialLocations(locationIds);
          setCurrentLocations(locationIds);
      } else if (!locationModalVisible) {
          console.log("Modal is closed, resetting locations and selected user");
          setSelectedUser(null); // Clear selected user when modal closes
          setInitialLocations([]);
          setCurrentLocations([]);
      }
  }, [locationModalVisible, selectedUserMemo]); // Using selectedUserMemo

  const handleOk = () => {
    console.log("OK clicked, current locations:", currentLocations, "initial locations:", initialLocations);
    if (!selectedUser) {
      console.error("No user selected.");
      return;
    }

    // Determine locations to add or remove based on initial and current selections.
    const locationsToAdd = currentLocations.filter(loc => !initialLocations.includes(loc));
    const locationsToRemove = initialLocations.filter(loc => !currentLocations.includes(loc));

    console.log("Locations to add:", locationsToAdd, "Locations to remove:", locationsToRemove);

    if (locationsToAdd.length > 0 || locationsToRemove.length > 0) {
      modifyUserLocations(selectedUser.uid, locationsToAdd, locationsToRemove)
        .then(() => {
          message.success("Location updated successfully.");
          setLocationModalVisible(false);
          fetchUsers(); // Refresh user data to reflect changes in the UI
        })
        .catch(error => {
          console.error("Failed to modify user locations:", error);
          message.error("Failed to update locations.");
        });
    } else {
      message.info("No changes to update.");
      setLocationModalVisible(false);
    }
  };

  // Set newLocationId
  const handleChange = (newLocationIds) => {
    console.log("Before change. Current Locations:", currentLocations);
    setCurrentLocations(newLocationIds);
    console.log("After change. New Locations:", newLocationIds);
  };

  // Reload user data
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      message.error("Failed to load users.");
      setIsLoading(false);
    }
  };

  const modifyUserLocations = async (userId, locationsToAdd, locationsToRemove) => {
    console.log("Attempting to modify locations for userId:", userId);
    if (!userId) {
      console.error("No userId provided for modifying locations");
      message.error("Internal error: No user selected.");
      return;
    }
    const userRef = db.collection("users").doc(userId);
    const batch = db.batch();

    // Add or remove locations as needed.
    if (locationsToAdd.length > 0) {
      batch.update(userRef, {
        locationId: firebase.firestore.FieldValue.arrayUnion(...locationsToAdd)
      });
    }
    if (locationsToRemove.length > 0) {
      batch.update(userRef, {
        locationId: firebase.firestore.FieldValue.arrayRemove(...locationsToRemove)
      });
    }
    await batch.commit();
    console.log("Location update successful.");
    userRef.get().then(doc => {
      if (doc.exists) {
        const updatedUser = {...doc.data(), key: doc.id} as User;
        console.log("Updated user data:", updatedUser);
        setSelectedUser(updatedUser); // Update the selected user with fresh data
        setInitialLocations(updatedUser.locationId || []);
        setCurrentLocations(updatedUser.locationId || []);
        fetchUsers(); // Refresh the whole user list to show updated table
      } else {
        console.error("No user found with id:", userId);
        message.error("Failed to fetch updated user data.");
      }
    }).catch(error => {
      console.error("Failed to fetch updated user data:", error);
      message.error("Failed to fetch updated user data.");
    });
  };
  

  // Fetch users and locations
  useEffect(() => {
    const loadData = async () => {
      const fetchedUsers = await getUsers();
      const fetchedLocations = await fetchLocations();
      const newLocationMap = fetchedLocations.reduce((map, location) => ({
        ...map,
        [location.id]: location.address, // map location ID to address
      }), {});
    
      setLocationMap(newLocationMap);
      setUsers(fetchedUsers);
    };
    loadData();
  }, []);

  const loadUsers = async () => {
    setIsLoading(true);
    let newUsers = await getUsers();
    setUsers(newUsers);
    setIsLoading(false);
  };

  useEffect(() => {
    const loadLocations = async () => {
      const fetchedLocations = await fetchLocations();
      setLocations(fetchedLocations);
    };

    loadLocations();
  }, []); // Empty dependency array ensures this only runs once on mount

  useEffect(() => {
    loadUsers();
  }, []);

  const columns = [
    {
      title: "Navn",
      key: "name",
      sorter: (a: User, b: User) => {
        if (a.name == null || b.name == null) return 0;
        return a.name.localeCompare(b.name);
      },
      render: (text: string, record: User) => {
        return (
          <Space>
            <Avatar size={30} src={record.photo || ""} />
            {record.disabled ? (
              <Text delete type="secondary">
                {record.name}
              </Text>
            ) : (
              <Text>{record.name}</Text>
            )}
          </Space>
        );
      },
    },

    {
      title: "E-mail",
      key: "email",
      sorter: (a: User, b: User) => {
        if (a.email == null || b.email == null) return 0;
        return a.email.localeCompare(b.email);
      },
      render: (text: string, record: User) =>
        record.disabled ? (
          <Text delete type="secondary">
            {record.email}
          </Text>
        ) : (
          <Text>{record.email}</Text>
        ),
    },
    {
      title: "Lokationer",
      dataIndex: "locationId",
      key: "location",
      render: (locationIds) => {
        const ids = Array.isArray(locationIds) ? locationIds : [];
        const locationAddresses = ids.map(id => locationMap[id] || "No Location Assigned").join(", ");
        return locationAddresses || "No Location Assigned";
      },
    }
    {
      title: "Virksomhed",
      dataIndex: "company",
      key: "company",
      sorter: (a: User, b: User) => {
        if (a.company == null || b.company == null) return 0;
        return a.company.localeCompare(b.company);
      },
      render: (text: string, record: User) =>
        record.disabled ? (
          <Text delete type="secondary">
            {record.company}
          </Text>
        ) : (
          <Text>{record.company}</Text>
        ),
    },

    {
      title: "Status",
      key: "status",
      filters: [
        {
          text: "Godkendt",
          value: "approved",
        },
        {
          text: "Ikke godkendt",
          value: "not-approved",
        },
        {
          text: "Admin",
          value: "is-admin",
        },
        {
          text: "Deaktiveret",
          value: "disabled",
        },
        {
          text: "Frokostordning",
          value: "lunchplan",
        },
      ],
      filterMultiple: false,
      onFilter: (value: string, record: User) => {
        if (value == "approved") return record.approved;
        if (value == "not-approved")
          return !record.approved && !record.disabled;
        if (value == "is-admin") return record.isAdmin;
        if (value == "disabled") {
          setShowDisabled(true);
          return record.disabled;
        }
        if (value == "lunchplan") return record.lunchplan;
      },
      sorter: (a: User, b: User) => {
        if (a.name == null || b.name == null) return 0;
        if (a.approved && !b.approved) return 1;
        if (!a.approved && b.approved) return -1;
        return a.name.localeCompare(b.name);
      },
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      {
        title: "Status",
        key: "status",
        render: (text: string, record: User) => {
          let tags = [];
          if (record.disabled) {
            tags.push(
              <Tag color="#D57378" key="disabled">
                Deaktiveret
              </Tag>
            );
          }
          if (record.approved) {
            tags.push(
              <Tag color="#A6BB75" key="approved">
                Godkendt
              </Tag>
            );
          } else {
            tags.push(
              <Tag color="#B8B8BC" key="not-approved">
                Ikke godkendt
              </Tag>
            );
          }
          if (record.isAdmin) {
            tags.push(
              <Tag color="#A984AA" key="admin">
                Admin
              </Tag>
            );
          }
          if (record.isCompanyAdmin) {
            tags.push(
              <Tag color="#FF6961" key="companyAdmin">
                Company Admin
              </Tag>
            );
          }
          if (record.isSuperAdmin) {
            tags.push(
              <Tag color="#89CFF0" key="superAdmin">
                Super Admin
              </Tag>
            );
          }
          if (record.lunchplan) {
            tags.push(
              <Tag color="#D8A55D" key="lunchplan">
                Frokostordning
              </Tag>
            );
          }
          return tags;
        },
      },
    {
      title: "Handling",
      key: "action",
      align: "right",
      render: (text: string, record: User) => {
        const onClick = ({ key }) => {
          if (key === "approve") {
            approveUser(record.uid)
              .then(() => {
                message.success("Bruger godkendt");
                loadUsers();
              })
              .catch((err) => {
                console.log(err);
                message.error("Kunne ikke godkende bruger");
              });
          } else if (key === "activate") {
            activateUser(record.uid)
              .then(() => {
                message.success("Bruger aktiveret");
                loadUsers();
              })
              .catch((err) => {
                console.log(err);
                message.error("Kunne ikke aktivere bruger");
              });
          } else if (key === "deactivate") {
            confirm({
              icon: <ExclamationCircleOutlined />,
              title: "Bekræft deaktivering",
              content: "Vi du deaktivere " + record.name + "?",
              okText: "Ja",
              okButtonProps: { danger: true },
              onOk() {
                deactivateUser(record.uid)
                  .then(() => {
                    message.success("Bruger deaktiveret");
                    loadUsers();
                  })
                  .catch((err) => {
                    console.log(err);
                    message.error("Kunne ikke deaktivere bruger");
                  });
              },
            });
          } else if (key === "sendpush") {
            setTokens(record.tokens);
            setSendModalVisible(true);
          } else if (key === "lunchplan") {
            setLunchplan(record.uid, !record.lunchplan)
              .then(() => {
                message.success(
                  "Frokostordning " + record.lunchplan
                    ? "deaktiveret"
                    : "aktiveret"
                );
                loadUsers();
              })
              .catch((err) => {
                console.log(err);
                message.error("Kunne ikke ændre frokostordning");
              });
          } else if (key === "change-location") {
            setSelectedUser(record);
            setLocationModalVisible(true);
          }
        };
        const menu = () => {
          let items = [];
          if (record.tokens.length > 0) {
            items.push(<Menu.Item key="sendpush">Send push</Menu.Item>);
          }
          if (!record.disabled) {
            items.push(
              <Menu.Item key="change-location">Change Location</Menu.Item>
            );
          }
          if (record.disabled) {
            items.push(<Menu.Item key="activate">Aktiver</Menu.Item>);
          } else {
            if (!record.approved)
              items.push(<Menu.Item key="approve">Godkend</Menu.Item>);
            items.push(<Menu.Item key="lunchplan">Frokostordning</Menu.Item>);
            if (!record.isAdmin)
              items.push(
                <Menu.Item key="deactivate" danger>
                  Deaktiver
                </Menu.Item>
              );
          }
          return <Menu onClick={onClick}>{items}</Menu>;
        };
        return (
          <Dropdown overlay={menu}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const sendPushToMultiple = () => {
    let selectedUsers = selectedRowKeys.map((k) =>
      users.find((u) => u.key == k)
    );
    let initialTokens: string[] = [];
    let selectedTokens: string[] = selectedUsers.reduce(
      (a, b) => a.concat(b.tokens),
      initialTokens
    );
    setTokens(selectedTokens);
    setSendModalVisible(true);
  };

  useEffect(() => {
    if (locationModalVisible && selectedUser) {
      // Find the location that matches the selectedUser's locationId
      const userLocation = locations.find(
        (loc) => loc.id === selectedUser.locationId
      );
      // Set the selectedUser state with the found location's details or clear it if not found
      setSelectedUser((prev) => ({
        ...prev,
        location: userLocation ? userLocation.address : "",
        locationId: userLocation ? userLocation.id : null, // Ensure to handle null if not found
      }));
    }
  }, [locationModalVisible, selectedUser?.locationId, locations]);

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: User[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.disabled || record.tokens.length == 0,
    }),
  };

  return (
    <Layout>
      <Header>
        <TopNav />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div id="users">
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={users.filter((u) => !u.disabled || showDisabled)}
            rowSelection={rowSelection}
            footer={() => (
              <Row>
                <Col span={12}>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<MessageOutlined />}
                    disabled={selectedRowKeys.length == 0}
                    onClick={sendPushToMultiple}
                  >
                    Send push
                  </Button>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Space>
                    <Switch
                      loading={isLoading}
                      checked={showDisabled}
                      onChange={setShowDisabled}
                    />
                    <Text type="secondary">Vis deaktiverede</Text>
                  </Space>
                </Col>
              </Row>
            )}
            locale={{ emptyText: "Ingen brugere" }}
          />
          <SendPushModal
            visible={sendModalVisible}
            setVisible={setSendModalVisible}
            tokens={tokens}
          />
          <Modal
            title="Change User Location"
            visible={locationModalVisible}
            onOk={handleOk}
            onCancel={() => setLocationModalVisible(false)}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select locations"
              value={currentLocations}
              onChange={handleChange}
            >
              {locations.map((loc) => (
                <Option key={loc.id} value={loc.id}>
                  {loc.address}
                </Option>
              ))}
            </Select>
          </Modal>
        </div>
      </Content>
      <Footer>
        <PoweredBy />
      </Footer>
    </Layout>
  );
};
