import firebase from "firebase/app";
import { auth } from "~/services/firebase";

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  auth
    .signInWithPopup(googleProvider)
    .then((res) => {
      console.log("logged in");
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const signOut = () => {
  auth
    .signOut()
    .then(() => {
      console.log("logged out");
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const emailSignIn = async (email: string, password: string) => {
  try {
    // Sign-in the user with the credential
    const userCredential = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    console.log("user credentials sign in", userCredential);

    return userCredential;
  } catch (error) {
    throw error;
  }
};
