import { db } from '~/services/firebase';
import moment from 'moment';

export type Company = {
    id: string;
    name: string;
    created: moment.Moment;
}

// Fetch all companies
export const fetchCompanies = async (): Promise<Company[]> => {
    console.log("Fetching companies...");
    return new Promise<Company[]>(resolve => {
        db.collection('companies').get().then((querySnapshot) => {
            let companies: Company[] = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                let created = data.created ? moment(data.created.toDate()) : moment();
                companies.push({
                    id: doc.id,
                    name: data.name,
                    created: created,
                });
            });
            resolve(companies);
        }).catch(error => {
            console.error("Error fetching companies: ", error);
            resolve([]); // Resolve with an empty array in case of error
        });
    });
};


// Function to add a new company
export const addCompany = async (companyName: string) => {
    const newCompanyRef = db.collection('companies').doc();  // Auto-generate document ID
    await newCompanyRef.set({
        name: companyName,
        created: new Date()  // Use JavaScript's Date object, Firestore converts this into its timestamp
    });
    return newCompanyRef.id;  // Return the new document ID
};

// in your companies service
export const updateCompanyName = async (companyId: any, name: any) => {
    const companyRef = db.collection('companies').doc(companyId);
    return companyRef.update({ name });
  };

// Function to delete a company
export const deleteCompany = async (companyId: string): Promise<void> => {
    const companyRef = db.collection('companies').doc(companyId);
    await companyRef.delete();
    console.log(`Deleted company`);
};