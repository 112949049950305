import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

export const PoweredBy = () => {
  const MONNER = [
    "Student Sweat",
    "Student Tears",
    "Last Minute Panic",
    "Caffeine Overdoses",
    "Procrastinating Students",
    "Student Acholism",
    "Forgotten Deadlines",
    "Group Project Frustrations",
    "Library All-Nighters",
    "Endless Study Sessions",
    "Failed Exam Attempts",
    "Overdue Library Books",
    "Desperate Pleas for Extensions",
    "Printer Malfunctions at Exams",
    "Lost Lecture Notes",
    "Missed Morning Alarms",
    "Frantic Exam Cramming",
  ];

  /*const MONNER = [
    'MONSTER ENERGY','MONSTER THE DOCTOR','MONSTER ASSAULT','MONSTER LH44',
    'MONSTER ULTRA CITRON','MONSTER ULTRA VIOLET','MONSTER ULTRA PARADISE',
    'MONSTER KHAOS','MONSTER MANGO LOCO','MONSTER PIPELINE PUNCH','MONSTER MIXXD',
    ]
    */

  return (
    <Text className="poweredby">
      Powered by {MONNER[Math.floor(Math.random() * MONNER.length)]}
    </Text>
  );
};
