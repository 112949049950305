import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Button,
  PageHeader,
  Table,
  Input,
  Form,
  Modal,
} from "antd";
import { TopNav, PoweredBy } from "~/components";
import {
  fetchLocations,
  addLocation,
  updateLocationAddress,
  deleteLocation,
} from "~/services/locations";

const { Header, Footer, Content } = Layout;

export const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [newLocationAddress, setNewLocationAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: { key: string }) => record.key === editingKey;
  const [form] = Form.useForm();

  const edit = (record) => {
    form.setFieldsValue({ address: record.address });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...locations];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setLocations(newData);
        setEditingKey("");
        await updateLocationAddress(key, row.address);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchLocations()
      .then((data) => {
        const sortedData = data
          .map((location) => ({
            key: location.id,
            address: location.address,
            created: location.created,
          }))
          .sort((a, b) => {
            if (
              typeof a.address === "string" &&
              typeof b.address === "string"
            ) {
              return a.address.localeCompare(b.address);
            }
            return 0;
          }); // Sorting alphabetically by address
        setLocations(sortedData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch locations:", error);
        setIsLoading(false);
      });
  }, []);

  const handleDeleteLocation = async (locationId) => {
    setIsLoading(true);
    try {
      await deleteLocation(locationId);
      const updatedLocations = locations.filter(
        (location) => location.key !== locationId
      );
      setLocations(updatedLocations);
      console.log("Location successfully deleted");
    } catch (error) {
      console.error("Error deleting location:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const showDeleteConfirm = (locationId) => {
    Modal.confirm({
      title: "Er du sikker på at du vil slette dette sted?",
      content: "Når slettet, kan dette ikke gøres om.",
      okText: "Ja, slet",
      okType: "danger",
      cancelText: "Nej, fortryd",
      onOk() {
        handleDeleteLocation(locationId);
      },
    });
  };

  const handleAddLocation = () => {
    if (!newLocationAddress.trim()) return;
    setIsLoading(true);
    addLocation(newLocationAddress).then(() => {
      setNewLocationAddress("");
      setIsLoading(false);
      fetchLocations().then((data: any[]) => {
        setLocations(
          data.map((location) => ({
            key: location.id,
            address: location.address,
            created: location.created,
          }))
        );
      });
    });
  };

  const columns = [
    {
      title: "Adresse",
      dataIndex: "address",
      key: "address",
      editable: true,
      render: (text, record) => {
        if (isEditing(record)) {
          return (
            <Form.Item
              style={{ margin: 0 }}
              name="address"
              rules={[
                { required: true, message: "Indtast venligst adressen!" },
              ]}
            >
              <Input
                onPressEnter={() => save(record.key)}
                onBlur={() => save(record.key)}
              />
            </Form.Item>
          );
        }
        return isEditing(record) ? <Input defaultValue={text} /> : text;
      },
    },
    {
      title: "Dato",
      dataIndex: "created",
      key: "created",
      render: (text, record) => text.format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Handling",
      key: "action",
      render: (_, record) => {
        return isEditing(record) ? (
          <span>
            <Button onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Gem
            </Button>
            <Button onClick={cancel}>Fortryd</Button>
          </span>
        ) : (
          <>
            <Button disabled={editingKey !== ""} onClick={() => edit(record)}>
              Rediger
            </Button>
            <Button
              type="danger"
              onClick={() => showDeleteConfirm(record.key)}
              style={{ marginLeft: 8 }}
            >
              Slet
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Layout>
      <Header>
        <TopNav />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <PageHeader title="Steder" subTitle="Tilføj, rediger eller slet steder">
          <Input
            placeholder="Ny adresse"
            value={newLocationAddress}
            onChange={(e) => setNewLocationAddress(e.target.value)}
            onPressEnter={handleAddLocation}
            style={{ width: 300, marginRight: 8 }}
            disabled={isLoading}
          />
          <Button
            type="primary"
            onClick={handleAddLocation}
            disabled={isLoading || !newLocationAddress.trim()}
          >
            Tilføj
          </Button>
        </PageHeader>
        <Form form={form} component={false}>
          <Table
            columns={columns}
            dataSource={locations}
            pagination={false}
            loading={isLoading}
            rowKey="key"
          />
        </Form>
      </Content>
      <Footer>
        <PoweredBy />
      </Footer>
    </Layout>
  );
};
