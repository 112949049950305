import { db } from "~/services/firebase";
import moment from "moment";

export type Location = {
  id: string;
  address: string;
  created: moment.Moment;
};

// Fetch all locations
export const fetchLocations = async (): Promise<Location[]> => {
  console.log("Fetching locations...");
  return new Promise<Location[]>((resolve) => {
    db.collection("locations")
      .get()
      .then((querySnapshot) => {
        let locations: Location[] = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          let created = data.created ? moment(data.created.toDate()) : moment();
          locations.push({
            id: doc.id,
            address: data.address,
            created: created,
          });
        });
        resolve(locations);
      })
      .catch((error) => {
        console.error("Error fetching locations: ", error);
        resolve([]); // Resolve with an empty array in case of error
      });
  });
};

// Function to add a new location
export const addLocation = async (
  locationAddress: string
): Promise<Location> => {
  const newLocationRef = db.collection("locations").doc(); // Auto-generate document ID
  const created = new Date();
  await newLocationRef.set({
    address: locationAddress,
    created: created, // Use JavaScript's Date object, Firestore converts this into its timestamp
  });
  return {
    id: newLocationRef.id,
    address: locationAddress,
    created: moment(created),
  }; // Return the new location object
};
// Function to update a location's address
export const updateLocationAddress = async (locationId: any, address: any) => {
  const locationRef = db.collection("locations").doc(locationId);
  return locationRef.update({ address });
};

// Function to delete a location
export const deleteLocation = async (locationId: string): Promise<void> => {
  const locationRef = db.collection("locations").doc(locationId);
  await locationRef.delete();
  console.log(`Deleted location`);
};
