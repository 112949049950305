import { db } from "~/services/firebase";
import firebase from "firebase";

export type User = {
  key: string;
  uid: string;
  approved: boolean;
  disabled: boolean;
  lunchplan: boolean;
  name: string;
  email: string;
  photo: string;
  floor: number;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isCompanyAdmin: boolean;
  hd: string;
  updated: string;
  tokens: string[];
  company: string;
  address: string;
  locationId: string[];
};

export const getUsers = async () => {
  return new Promise<User[]>((resolve) => {
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        let users: User[] = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          users.push({
            key: doc.id,
            uid: doc.id,
            approved: data.approved || false,
            disabled: data.disabled || false,
            lunchplan: data.lunchplan || false,
            name: data.name,
            email: data.email,
            photo: data.photo,
            floor: data.floor,
            isAdmin: data.isAdmin || false,
            isSuperAdmin: data.isSuperAdmin || false,
            isCompanyAdmin: data.isCompanyAdmin || false,
            hd: data.hd,
            updated: data.updated,
            tokens: data.tokens || [],
            company: data.company,
            address: data.address || "No address specified",
            locationId: data.locationId || [],
          });
        });
        resolve(users);
      });
  });
};

export const setLunchplan = async (uid: string, lunchplan: boolean) => {
  return new Promise((resolve, reject) => {
    var docRef = db.collection("users").doc(uid);
    docRef
      .update({
        lunchplan,
      })
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        console.error("Could not change lunchplan", err);
        reject(err);
      });
  });
};

export const approveUser = async (uid: string) => {
  return new Promise((resolve, reject) => {
    var docRef = db.collection("users").doc(uid);
    docRef
      .update({
        approved: true,
      })
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        console.error("Could not approve user", err);
        reject(err);
      });
  });
};

export const activateUser = async (uid: string) => {
  return new Promise((resolve, reject) => {
    var docRef = db.collection("users").doc(uid);
    docRef
      .update({
        approved: true,
        disabled: false,
      })
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        console.error("Could not activate user", err);
        reject(err);
      });
  });
};

export const deactivateUser = async (uid: string) => {
  return new Promise((resolve, reject) => {
    var docRef = db.collection("users").doc(uid);
    docRef
      .update({
        approved: false,
        disabled: true,
      })
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        console.error("Could not deactivate user", err);
        reject(err);
      });
  });
};

export const updateFloor = async (uid: string, floor: number) => {
  return new Promise((resolve, reject) => {
    var docRef = db.collection("users").doc(uid);
    docRef
      .update({
        floor: floor,
      })
      .then(function () {
        resolve();
      })
      .catch(function (err) {
        console.error("Could not update floor for user", err);
        reject(err);
      });
  });
};

export const approvedUsersCount = async () => {
  return new Promise<number>((resolve) => {
    db.collection("users")
      .where("approved", "==", true)
      .get()
      .then((querySnapshot) => {
        resolve(querySnapshot.size);
      });
  });
};

export const updateLocation = async (userId: string, locations: string[]) => {
  const userRef = db.collection("users").doc(userId);
  try {
    await userRef.update({
      locationId: firebase.firestore.FieldValue.arrayUnion(...locations),
    });
  } catch (error) {
    console.error("Error updating user location: ", error);
    throw new Error("Failed to update location");
  }
};
