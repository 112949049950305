import React, {
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
} from "react";
import { auth, db } from "~/services/firebase";

interface AuthContextProps {
  user: firebase.User | null;
  setUser: any;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isCompanyAdmin: boolean;
  isSuperAdmin: boolean;
  isLoaded: boolean;
}

export const AuthContext = createContext<Partial<AuthContextProps>>({});

export const AuthProvider: React.FC<PropsWithChildren<any>> = (props) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        setUser(user);
        // check if user exists in firebase and isAdmin
        db.collection("users")
          .doc(user.uid)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              const data = doc.data();
              setIsAdmin(data?.isAdmin || false);
              setIsSuperAdmin(data?.isSuperAdmin || false);
              setIsCompanyAdmin(data?.isCompanyAdmin || false);
            }
            setIsLoaded(true);
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
            setUser(null);
            setIsAdmin(false);
            setIsCompanyAdmin(false);
            setIsSuperAdmin(false);
            setIsLoaded(false);
          });
      } else {
        setUser(null);
        setIsAdmin(false);
        setIsCompanyAdmin(false);
        setIsSuperAdmin(false);
        setIsLoaded(false);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isAdmin,
        isSuperAdmin,
        isCompanyAdmin,
        isLoaded,
        isAuthenticated: user !== null,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
