import React, { FC, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import daDK from "antd/es/locale/da_DK";
import { AuthProvider, AuthContext } from "~/providers/AuthProvider";
import {
  AccessDenied,
  Dashboard,
  Loader,
  NotFound,
  Registrations,
  SignIn,
  Users,
  Companies,
  Locations,
} from "~/screens";

const RequireAuth: FC<any> = (props) => {
  const location = useLocation();
  const { isAuthenticated, isAdmin, isLoaded } = useContext(AuthContext);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: location },
        }}
      />
    );
  }

  if (!isLoaded) {
    return <Loader />;
  }

  if (!isAdmin) {
    return <Redirect to="/accessdenied" />;
  }

  return props.children;
};

const AppRouter = () => {
  const { isAuthenticated, isAdmin, isLoaded } = useContext(AuthContext);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/dashboard" />;
          }}
        />
        <Route
          exact
          path="/signin"
          render={({ location }) => {
            const { from } = location.state || {
              from: { pathname: "/dashboard" },
            };
            if (isAuthenticated) {
              if (isLoaded) {
                if (isAdmin) {
                  return <Redirect to={from} />;
                } else {
                  return <Redirect to="/accessdenied" />;
                }
              } else {
                return <Loader />;
              }
            }
            return <SignIn />;
          }}
        />
        <Route exact path="/accessdenied" component={AccessDenied} />
        <RequireAuth>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route
            exact
            path={["/registrations", "/registrations/:week"]}
            component={Registrations}
          />
          <Route exact path="/users" component={Users} />
          <Route exact path="/companies" component={Companies} />
          <Route path="/locations" component={Locations} />
        </RequireAuth>
        <Route exact path="/notfound" component={NotFound} />
        <Route path="*">
          <Redirect to="/notfound" />
        </Route>
      </Switch>
    </Router>
  );
};

export const App = () => {
  return (
    <ConfigProvider locale={daDK}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ConfigProvider>
  );
};
