import { db } from '~/services/firebase';
import { getUsers, User } from '~/services/users';

import moment, {Moment} from 'moment';
require('moment/locale/da');


export type RegistrationDays = {
    [ index: string ] : boolean
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
}

export type Registration = {
    uid: string
    user: User | undefined
    updated: Moment
    days: RegistrationDays
}

export const getRegistrations = async (date: Moment, locationFilter: string) => {
    return new Promise<Registration[]>(resolve => {

        const weekId = moment(date).format('YYYY-MM-DD');

        let query = db.collection('registrations').doc(weekId).collection('users');

        if (locationFilter !== 'all') {
            query = query.where(`days.${locationFilter}.registered`, '==', true);
          }
        // get all users
        getUsers().then((users: User[]) => {
            const weekId = moment(date).format('YYYY-MM-DD')
            db.collection('registrations').doc(weekId).collection('users').get().then((querySnapshot) => {
                let registrations: Registration[] = []
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    let updated = moment(data.updated.toDate())
                    let days = {...data}
                    delete days.updated
                    registrations.push({
                        uid: doc.id,
                        user: users.find((u) => u.uid == doc.id),
                        updated: updated,
                        days: <RegistrationDays>days,
                    })
                })
                resolve(registrations)
            })
        })
    })
}
