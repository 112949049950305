import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Avatar, Menu, Dropdown, Typography } from "antd";
import { AuthContext } from "~/providers/AuthProvider";
import { signOut } from "~/services/auth";

const { Title } = Typography;

export const TopNav = () => {
  const { user, isAdmin, isSuperAdmin } = useContext(AuthContext); // Assuming isSuperAdmin is needed for Locations

  const history = useHistory();

  const menu = (
    <Menu onClick={() => signOut()}>
      <Menu.Item key="0" danger>
        Log ud
      </Menu.Item>
    </Menu>
  );

  return (
    <Row className="topnav">
      <Col span="8">
        <Title level={2}>Frokost Admin</Title>
      </Col>
      {user && (
        <>
          {isAdmin ? (
            <Col span="8">
              <Menu
                mode="horizontal"
                defaultSelectedKeys={["dashboard"]}
                selectedKeys={[history.location.pathname.split("/")[1]]}
              >
                <Menu.Item key="dashboard">
                  <Link to="/dashboard">Oversigt</Link>
                </Menu.Item>
                <Menu.Item key="registrations">
                  <Link to="/registrations">Tilmeldinger</Link>
                </Menu.Item>
                <Menu.Item key="users">
                  <Link to="/users">Brugere</Link>
                </Menu.Item>
                <Menu.Item key="companies">
                  <Link to="/companies">Virksomheder</Link>
                </Menu.Item>
                <Menu.Item key="locations">
                  <Link to="/locations">Lokationer</Link>
                </Menu.Item>
              </Menu>
            </Col>
          ) : (
            <Col span="8" />
          )}
          <Col span="8">
            <Dropdown overlay={menu} trigger={["click"]}>
              <span className="user">
                <Avatar size={30} src={user.photoURL || ""} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "5px",
                  }}
                >
                  <div className="name">{user.displayName}</div>
                  <div className="email">{user.email}</div>
                </div>
              </span>
            </Dropdown>
          </Col>
        </>
      )}
    </Row>
  );
};
