import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Layout,
  Typography,
  DatePicker,
  Button,
  PageHeader,
  Select,
  Row,
  Col,
  Table,
  Progress,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { approvedUsersCount, User } from "~/services/users";
import { getRegistrations, Registration } from "~/services/registrations";
import { fetchLocations } from "~/services/locations";
import { TopNav, PoweredBy, DayList, DayListItem } from "~/components";
import moment, { Moment } from "moment";
require("moment/locale/da");

const { Header, Footer, Content } = Layout;
const { Text } = Typography;

interface TParams {
  week: string;
}

type ParticipantListItem = {
  key: string;
  name: string;
  updated: Moment;
};

export const Registrations = () => {
  const { week } = useParams<TParams>();
  const history = useHistory();

  const [selectedWeek, setSelectedWeek] = useState(
    moment(week).startOf("week")
  );

  const [usersCount, setUsersCount] = useState<number>(0);
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );
  const [participants, setParticipants] = useState<ParticipantListItem[]>([]);

  const [locationMap, setLocationMap] = useState({});

  const [locationsLoaded, setLocationsLoaded] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState("all");

  const loadRegistrations = (date: Moment, locationFilter: string) => {
    setIsLoading(true);
    getRegistrations(date, locationFilter) // Now passing both parameters
      .then((registrations) => {
        fetchLocations().then((locations) => {
          const locationMap = locations.reduce(
            (map, loc) => ({
              ...map,
              [loc.id]: loc.address,
            }),
            {}
          );

          setLocationMap(locationMap);
          setLocationsLoaded(true);

          const filteredRegistrations = registrations
            .map((reg) => {
              // Assuming each registration includes user details and a list of days they are registered
              return {
                ...reg,
                days: Object.keys(reg.days).reduce((result, day) => {
                  const dayInfo = reg.days[day];
                  // Check if the day's registration matches the selected location
                  if (
                    dayInfo.registered &&
                    (locationFilter === "all" ||
                      dayInfo.locationId === locationFilter)
                  ) {
                    result[day] = dayInfo;
                  }
                  return result;
                }, {}),
              };
            })
            .filter((reg) => Object.keys(reg.days).length > 0); // Only keep registrations that have any days left after filtering

          setRegistrations(filteredRegistrations);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    approvedUsersCount().then((count) => {
      setUsersCount(count);
    });
  }, []);

  useEffect(() => {
    setSelectedIndex(undefined);
    loadRegistrations(selectedWeek);
  }, [selectedWeek]);

  useEffect(() => {
    if (typeof selectedIndex !== "undefined" && registrations.length > 0) {
      let key: string = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
      ][selectedIndex];
      let items: ParticipantListItem[] = [];
      let regs = registrations.filter((r) => r.days[key]);
      regs.map((r) => {
        items.push({
          key: r.uid,
          name: r.user?.name || "-",
          updated: r.updated,
        });
      });
      setParticipants(items);
    } else {
      setParticipants([]);
    }
  }, [selectedIndex]);

  useEffect(() => {
    loadRegistrations(selectedWeek, selectedLocation);
  }, [selectedWeek, selectedLocation]);

  const handleDateChange = (date: Moment | null) => {
    let week = moment(date).startOf("week");
    setSelectedWeek(week);
    history.push(`/registrations/${moment(week).format("YYYY-MM-DD")}`);
  };

  const handleExportCSV = () => {
    if (!locationsLoaded) {
      alert("Please wait until location data has finished loading.");
      return;
    }

    const weekFormat = moment(selectedWeek).isoWeek();
    // Starting CSV content with UTF-8 Byte Order Mark (BOM)
    let csvContent = "\uFEFF";

    // Group registrations by location
    const registrationsByLocation = {};

    // Prepare data grouped by location and user
    registrations.forEach((reg) => {
      ["monday", "tuesday", "wednesday", "thursday", "friday"].forEach(
        (day) => {
          let dayInfo = reg.days[day];
          if (dayInfo && typeof dayInfo === "object" && dayInfo.registered) {
            const location =
              locationMap[dayInfo.locationId] || "No Location Assigned";
            const userKey = `${reg.user?.uid}-${location}`; // Unique key for each user and location
            if (!registrationsByLocation[location]) {
              registrationsByLocation[location] = {};
            }
            if (!registrationsByLocation[location][userKey]) {
              registrationsByLocation[location][userKey] = {
                name: reg.user?.name || "Unknown",
                company: reg.user?.company || "Unknown",
                days: {
                  Monday: "No",
                  Tuesday: "No",
                  Wednesday: "No",
                  Thursday: "No",
                  Friday: "No",
                },
              };
            }
            registrationsByLocation[location][userKey].days[
              day.charAt(0).toUpperCase() + day.slice(1)
            ] = "Yes";
          }
        }
      );
    });

    // Process each location group
    Object.keys(registrationsByLocation).forEach((location) => {
      // Add location header with the week number
      csvContent += `Week ${weekFormat};${location};\nName;Company;Monday;Tuesday;Wednesday;Thursday;Friday\n`;

      const userRegs = registrationsByLocation[location];
      const totals = {
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
      };

      Object.values(userRegs).forEach((reg) => {
        const daysData = reg.days;
        const dayValues = Object.values(daysData).join(";");
        csvContent += `${reg.name};${reg.company};${dayValues}\n`;

        // Count totals for each day
        Object.keys(daysData).forEach((day) => {
          if (daysData[day] === "Yes") totals[day]++;
        });
      });

      // Append the totals row for this location
      csvContent += `Total;;${totals.Monday};${totals.Tuesday};${totals.Wednesday};${totals.Thursday};${totals.Friday}\n\n`;
    });

    // Create and download the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `registrations-week-${weekFormat}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // setup table columns
  const columns = [
    {
      title: "Navn",
      key: "name",
      sorter: (a: User, b: User) => a.name.localeCompare(b.name),
      render: (text: string, record: ParticipantListItem) => (
        <Text>{record.name}</Text>
      ),
    },
    {
      title: "Dato",
      key: "date",
      defaultSortOrder: "ascend",
      sorter: (a: User, b: User) =>
        moment(a.updated).unix() - moment(b.updated).unix(),
      render: (text: string, record: ParticipantListItem) => (
        <Text>{moment(record.updated).format("LLL")}</Text>
      ),
    },
  ];

  const percent =
    usersCount > 0
      ? Math.floor(Math.min(100, (100 / usersCount) * registrations.length))
      : 0;

  return (
    <Layout>
      <Header>
        <TopNav />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div id="registrations">
          <PageHeader
            title={"Uge " + moment(selectedWeek).isoWeek()}
            subTitle={
              moment(selectedWeek).format("D. MMM") +
              " - " +
              moment(selectedWeek).add(6, "days").format("D. MMM")
            }
            extra={[
              <Button
                key="previousweek"
                type="primary"
                shape="circle"
                disabled={isLoading}
                onClick={() =>
                  handleDateChange(moment(selectedWeek).subtract(1, "week"))
                }
              >
                <LeftOutlined />
              </Button>,
              <Button
                key="nextweek"
                type="primary"
                shape="circle"
                disabled={isLoading}
                onClick={() =>
                  handleDateChange(moment(selectedWeek).add(1, "week"))
                }
              >
                <RightOutlined />
              </Button>,
              <DatePicker
                key="selectedweek"
                disabled={isLoading}
                picker="week"
                value={selectedWeek}
                allowClear={false}
                onChange={(date) => handleDateChange(date)}
              />,
              <Select
                defaultValue="all"
                style={{ width: 200 }}
                onChange={(value) => {
                  setSelectedLocation(value);
                  loadRegistrations(selectedWeek, value);
                }}
              >
                <Select.Option value="all">All Locations</Select.Option>
                {Object.entries(locationMap).map(([id, address]) => (
                  <Select.Option key={id} value={id}>
                    {address}
                  </Select.Option>
                ))}
              </Select>,
              <Button
                key="export"
                type="primary"
                onClick={handleExportCSV}
                disabled={!locationsLoaded || isLoading}
              >
                Opret .csv fil
              </Button>,
            ]}
          >
            <Row gutter={16}>
              <Col span={12}>
                <DayList
                  loading={isLoading}
                  selectedWeek={selectedWeek}
                  selectedIndex={selectedIndex}
                  registrations={registrations}
                  onClick={(day: DayListItem, index: number) => {
                    setSelectedIndex(index);
                  }}
                />
                <Progress
                  percent={percent}
                  format={(percent) =>
                    `${registrations.length}/${usersCount || 0}`
                  }
                  showInfo={usersCount > 0}
                />
              </Col>
              <Col span={12}>
                {typeof selectedIndex !== "undefined" ? (
                  <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={participants}
                    pagination={false}
                    locale={{ emptyText: "Ingen tilmeldinger" }}
                  />
                ) : null}
              </Col>
            </Row>
          </PageHeader>
        </div>
      </Content>
      <Footer>
        <PoweredBy />
      </Footer>
    </Layout>
  );
};
