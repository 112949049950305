import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Button,
  PageHeader,
  Row,
  Col,
  Table,
  Input,
  Form,
  Modal,
} from "antd";
import { TopNav, PoweredBy } from "~/components";
import {
  fetchCompanies,
  addCompany,
  updateCompanyName,
  deleteCompany,
} from "~/services/companies";

const { Header, Footer, Content } = Layout;
const { Text } = Typography;

export const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [editingName, setEditingName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: { key: string }) => record.key === editingKey;
  const [form] = Form.useForm();

  const edit = (record) => {
    form.setFieldsValue({ name: record.name });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...companies];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setCompanies(newData);
        setEditingKey("");
        await updateCompanyName(key, row.name);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCompanies()
      .then((data) => {
        const sortedData = data
          .map((company) => ({
            key: company.id,
            name: company.name,
            created: company.created,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically by name
        setCompanies(sortedData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch companies:", error);
        setIsLoading(false);
      });
  }, []);

  const handleDeleteCompany = async (companyId) => {
    setIsLoading(true); // Optional: Show loading state during the operation
    try {
      await deleteCompany(companyId);
      const updatedCompanies = companies.filter(
        (company) => company.key !== companyId
      );
      setCompanies(updatedCompanies); // Update state to reflect the deletion
      console.log("Company successfully deleted");
    } catch (error) {
      console.error("Error deleting company:", error);
    } finally {
      setIsLoading(false); // Stop showing loading state regardless of outcome
    }
  };

  const showDeleteConfirm = (companyId) => {
    Modal.confirm({
      title: "Er du sikker på at du vil slette denne virksomhed?",
      content: "Når slettet, kan dette ikke gøres om.",
      okText: "Ja, slet",
      okType: "danger",
      cancelText: "Nej, fortryd",
      onOk() {
        handleDeleteCompany(companyId);
      },
    });
  };

  const handleAddCompany = () => {
    if (!newCompanyName.trim()) return;
    setIsLoading(true);
    addCompany(newCompanyName).then(() => {
      setNewCompanyName("");
      setIsLoading(false);
      fetchCompanies().then((data: any[]) => {
        setCompanies(
          data.map((company) => ({
            key: company.id,
            name: company.name,
            created: company.created,
          }))
        );
      });
    });
  };

  const columns = [
    {
      title: "Virksomheder",
      dataIndex: "name",
      key: "name",
      editable: true,
      render: (text, record) => {
        if (isEditing(record)) {
          return (
            <Form.Item
              style={{ margin: 0 }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Indtast venligst virksomhedsnavnet!",
                },
              ]}
            >
              <Input
                onPressEnter={() => save(record.key)}
                onBlur={() => save(record.key)}
              />
            </Form.Item>
          );
        }
        return isEditing(record) ? <Input defaultValue={text} /> : text;
      },
    },
    {
      title: "Dato",
      dataIndex: "created",
      key: "created",
      render: (text, record) => text.format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return isEditing(record) ? (
          <span>
            <Button onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Gem
            </Button>
            <Button onClick={cancel}>Fortryd</Button>
          </span>
        ) : (
          <>
            <Button disabled={editingKey !== ""} onClick={() => edit(record)}>
              Rediger
            </Button>
            <Button
              type="danger"
              onClick={() => showDeleteConfirm(record.key)}
              style={{ marginLeft: 8 }}
            >
              Slet
            </Button>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: editingKey === record.key,
      }),
    };
  });

  // Use the editable cell component
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    children,
    dataIndex, // This should not be passed to the DOM
    title, // Nor should this
    record, // Nor this
    ...restProps // Capture and pass on only the props that are valid for the DOM element
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              { required: true, message: "Please input the company name!" },
            ]}
          >
            <Input
              autoFocus
              onPressEnter={() => save(restProps.record.key)}
              onBlur={() => save(restProps.record.key)}
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleSave = async (key, dataIndex, value) => {
    const newData = [...companies];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [dataIndex]: value });
      setCompanies(newData);
      setEditingKey("");
      // Call a function to update the data in the database
      await updateCompanyName(key, value);
    }
  };

  return (
    <Layout>
      <Header>
        <TopNav />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <PageHeader
          title="Virksomheder"
          subTitle="Tilføj, rediger eller slet virksomheder"
        >
          <Input
            placeholder="Nyt virksomedsnavn"
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
            onPressEnter={handleAddCompany}
            style={{ width: 300, marginRight: 8 }}
            disabled={isLoading}
          />
          <Button
            type="primary"
            onClick={handleAddCompany}
            disabled={isLoading || !newCompanyName.trim()}
          >
            Tilføj
          </Button>
        </PageHeader>
        <Form form={form} component={false}>
          <Table
            columns={mergedColumns}
            dataSource={companies}
            pagination={false}
            loading={isLoading}
            rowKey="key"
          />
        </Form>
      </Content>
      <Footer>
        <PoweredBy />
      </Footer>
    </Layout>
  );
};
